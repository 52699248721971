<script setup lang="ts">
const { isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route = useRoute();
const rName: any = route.name || "";
if (hasLength(rName)) {
  title.value = strCapitalized(rName);
}

onMounted(() => {
  console.log("rName:", rName);
});
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeaderMerchant class="max-w-full p-[16px]" />
  <slot />
  <LazyFooter class="w-full p-[16px] flex-row" />
</template>
